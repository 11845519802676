import React, { useContext } from "react";

import { Box, Divider, Flex, Heading, Paragraph, Text } from "theme-ui";
import Edit from "@material-ui/icons/Edit";

import capitalize from "lodash/capitalize";

import { AuthContext } from "Authentication/AuthProvider";
import BrandLogo from "components/BrandLogo";
import Link from "components/Link";

const Entry = ({ title, children, isLastItem, editLink, editionDisabled }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          bg: "componentBG3",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Text variant="overline" sx={{ color: "text.secondary" }}>
            {title}
          </Text>
          {children}
        </Box>
        {!editionDisabled && (
          <Link to={editLink}>
            <Edit />
          </Link>
        )}
      </Box>
      {!isLastItem && <Divider sx={{ width: "full", my: 0 }} />}
    </>
  );
};

const PersonalInformation = () => {
  const { profile } = useContext(AuthContext);

  return (
    <>
      <Heading variant="heading6" sx={{ ml: [4, 0], mb: [4, 10] }}>
        Personal information
      </Heading>
      {/*Hided until we looking for use the profile photo <Flex sx={{ p: 6, flexDirection: "column", alignItems: "center" }}>
        <Box
          sx={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "primary.border",
            transform: "translateZ(0)",
            borderRadius: "50%",
          }}
        >
          <BrandLogo width={32} />
        </Box>
         <Button variant="text" sx={{ mt: 6 }} onClick={() => alert("WIP")}>
          Change profile photo
        </Button> 
      </Flex> */}
      <Flex sx={{ height: "fit-content", flexDirection: "column" }}>
        <Box sx={{ bg: "componentBG1", borderRadius: 1, p: 2 }}>
          <Entry
            title="NAME"
            editLink="/profile/personal-information/name/edit"
          >
            <Paragraph variant="body1">
              {capitalize(profile?.title)} {profile?.firstName}{" "}
              {profile?.lastName}
            </Paragraph>
          </Entry>
          <Entry title="PHONE NUMBER" editionDisabled>
            <Paragraph variant="body1">{profile?.phoneNumber}</Paragraph>
          </Entry>
          <Entry title="EMAIL" isLastItem editionDisabled>
            <Paragraph variant="body1">{profile?.email}</Paragraph>
          </Entry>
        </Box>
      </Flex>
    </>
  );
};

export default PersonalInformation;
